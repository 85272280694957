import React, {useCallback, useState} from 'react';
import MainComponent from "./main/Main";
import {Form} from "react-bootstrap";
import sendData from "../api/Data";
import CancelComponent from "./Cancel";


function ContentComponent(props) {
    const [validated, setValidated] = useState(false);
    let [formStatus, setFormStatus] = useState(null);

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        // check security fields and required fields validation
        if (form.elements.fax.value === '' && form.elements.mailConfirm.value === '' && form.checkValidity() !== false) {

            setFormStatus('processing');

            let data = JSON.stringify({
                category: form.elements.category.value,
                time: form.elements.dateTimeSelect.value,
                date: form.elements.date.value,
                salutation: form.elements.salutation.value,
                name: form.elements.name.value,
                street: form.elements.street.value,
                zip: form.elements.zip.value,
                city: form.elements.city.value,
                email: form.elements.email.value,
                phone: form.elements.phone.value,
                comment: form.elements.comment.value,
            });

            sendData(data, 'submitForm').then(function (response) {
                setFormStatus(response);
            });
        }

        setValidated(true);
    };

    // show alert, before page refresh
    const alertUser = useCallback((e) => {
        e.preventDefault()
        e.returnValue = ''
    }, [])

    if(formStatus !== 'success') {
        window.addEventListener('beforeunload', alertUser)
    } else {
        window.removeEventListener('beforeunload', alertUser)
    }

    // check if cancel parameter is set
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let [cancel, setCancel] = useState(urlParams.get('cancel'));

    // Todo: remove cancel param from url
    const handleAfterCancellation = () => {
        setCancel(null);
    }

    if(cancel !== null)
    {
        return (
            <div className="container">
                <div className="col-xl-8">
                    <div className="cancel-component">
                        <CancelComponent
                            dateGuid={cancel}
                            afterCancellation={handleAfterCancellation}
                        />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="container">
                <Form noValidate validated={validated} onSubmit={handleSubmit} className={ `form-status-${formStatus}` }>
                    <MainComponent
                        config={props.config}
                        formStatus={formStatus}
                        formValid={validated}
                    />
                </Form>
            </div>
        )
    }
}

export default ContentComponent;