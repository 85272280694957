import React from 'react';
import DatePicker, {registerLocale} from "react-datepicker";
import { ReactComponent as Loader } from './../../../../../assets/img/icons/loader.svg';
import de from 'date-fns/locale/de';
import sendData from "../../../../api/Data";
import TimeSelectComponent from "./TimeSelect";
import Form from 'react-bootstrap/Form';
import { withTranslation } from 'react-i18next';

registerLocale('de', de);

class DateSelectComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            selectedDate: null,
            dateString: null,
            componentChanged: false,
            dateItems: [],
            date: [],
            selected: true,
            time: '',
            options: {year: 'numeric', month: '2-digit', day: '2-digit'}
        }

        this.time = React.createRef();

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.getDateData      = this.getDateData.bind(this);
        this.handleMonthChange = this.handleMonthChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.category !== prevProps.category) {
            this.resetDate();
        }

        if (this.props.formStatus !== prevProps.formStatus) {
            if(this.props.formStatus == 'failed') {
                this.resetDate();
            }
        }
    }

    resetDate() {
        this.setState({
            componentChanged: true,
            date: [],
            selectedDate: null,
            dateString: null
        })
        this.getDateData(null);
    }

    render() {
        const {isLoaded, componentChanged, selectedDate, dateString, selected, options} = this.state;

        if (isLoaded) {
            const startDate = new Date(this.state.dateItems.startDate);

            // create list of available dates
            const isWeekday = (date) => {
                const day = date.toLocaleDateString('de-DE', options);
                return this.state.dateItems.activeDays.includes(day);
            };

            // TODO: disable prev month arrow, basend on min date
            // set first available date to selected
            if((selectedDate === null && dateString === null) || selected === false) {
                this.setState({selectedDate: this.convertDate(this.state.dateItems.activeDays[0])})
                this.setState({dateString: this.convertDate(this.state.dateItems.activeDays[0]).toLocaleDateString('en-EN', options)});
                this.setState({selected: true})
            }

            return (
                <div>
                    <div className="failed-info">
                        <div className="alert alert-warning">
                            {this.props.t('card.form.failed')}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-12">
                            <DatePicker
                                inline
                                locale="de"
                                filterDate={isWeekday}
                                startDate={startDate}
                                minDate={startDate}
                                selected={selectedDate}
                                fixedHeight
                                onChange={(date) => {
                                    this.props.onDateChange();
                                    this.setState({
                                        selected: true,
                                        selectedDate: date,
                                        dateString: date.toLocaleDateString('en-EN', options)
                                    });
                                    this.handleDateChange();
                                }}
                                onMonthChange={this.handleMonthChange}
                            />
                            <div className="d-none">
                                <Form.Control type="text" name="date" value={this.state.dateString} autocomplete="off"/>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-12" ref={this.time}>
                            <TimeSelectComponent
                                dateString={this.state.dateString}
                                selectedCategory={this.props.category}
                                onTimeChange={this.handleTimeChange}
                            />
                        </div>
                    </div>
                </div>
            );
        }  else {
            if(componentChanged) {
                return (
                    <div className="row">
                        <div className="col-12 text-center">
                            <p>Bitte haben Sie einen Moment Geduld. <br/>Wir suchen passende Termine für Sie heraus.</p>
                            <Loader fill={this.props.color} width="64"/>
                        </div>
                    </div>
                );
            }
        }
    }

    // scroll to time selection on small devices
    handleDateChange() {
        if(window.innerWidth < '460') {
            this.time.current.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
        }
    }

    // trigger status update on time change
    handleTimeChange(time) {
        this.props.onDateChange(
            this.state.selectedDate.toLocaleDateString('de-DE', this.state.options) + ', ' + time
        );
    }

    // trigger date change event on month change
    handleMonthChange(event) {
        this.getDateData(event);
        this.props.onDateChange();
        this.setState({selected: false})
    }

    getDateData(date) {
        this.setState({isLoaded: false})

        if(date === null) {
            date = new Date();
        }

        const selectedData =
            {
                "category": this.props.category,
                "date": date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
            };

        sendData(selectedData, 'getDatesByMonth').then(function (dates) {
            // check if there are available dates in current month, else jump to next month
            if (dates.activeDays.length === 0) {
                let current = '';
                const startDate = new Date(dates.startDate);

                if (startDate.getMonth() === 11) {
                    current = new Date(startDate.getFullYear() + 1, 0, 1);
                } else {
                    current = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
                }
                this.getDateData(current);
            } else {
                this.setState({
                    dateItems: dates,
                    isLoaded: true
                })
            }

        }.bind(this));
    }

    convertDate(date) {
        if(date) {
            const splitDate = date.split(".");
            return new Date(+splitDate[2], splitDate[1] - 1, +splitDate[0]);
        }
    }
}

export default withTranslation('text')(DateSelectComponent);