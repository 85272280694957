import React from 'react';
import FormCheck from 'react-bootstrap/FormCheck'
import sendData from "../../../../api/Data";

class CategorySelectComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typeItems: [],
            selectedCategory: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        sendData(null, 'getCategories').then(function (data) {
            this.setState({ typeItems: data })
        }.bind(this));
    }

    render() {
        return (
            <div className="Type-select">
                {this.state.typeItems.map(typeItem => {
                    return <FormCheck className="form-check-inline">
                        <FormCheck.Input
                            className="btn-check"
                            type="radio"
                            name="category"
                            id={typeItem.value}
                            value={typeItem.value}
                            onClick={this.handleClick}
                            onChange={this.handleChange}
                            />
                        <FormCheck.Label
                            className="btn btn-outline-primary"
                            for={typeItem.value}
                        >{typeItem.name}</FormCheck.Label>
                    </FormCheck>
                })}
            </div>
        )
    }

    handleChange(event) {
        this.props.onCategoryChange(event.target.value);
    }

    handleClick(event) {
        this.props.onCategoryClick(event.target.value);
    }
}

export default CategorySelectComponent;