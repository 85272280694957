import React from 'react';
import { useTranslation } from 'react-i18next';
import avrioLogo from './../../assets/img/logos/avrio.svg';

function FooterComponent(props) {
    const { t } = useTranslation('text');
    const config = props.config;

    return (
        <footer className="App-footer">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-12">
                        <div className="text-center">
                            <p className="mb-3">{t('footer.productInfo')}
                                <a href="https://www.avrio-marketing.de/" target="_blank" rel="noreferrer">
                                    <img src={avrioLogo} alt="avrio Logo" width="60" height="26" />
                                </a>
                            </p>
                            <ul className="list-inline">
                                {config.linkToImprint &&
                                    <li className="list-inline-item">
                                        <a href={config.linkToImprint} target="_blank" rel="noreferrer">{t('footer.nav.imprint')}</a>
                                    </li>
                                }
                                {config.linkToPrivacyPolicy &&
                                <li className="list-inline-item">
                                    <a href={config.linkToPrivacyPolicy} target="_blank" rel="noreferrer">{t('footer.nav.privacyPolicy')}</a>
                                </li>
                                }
                                {config.linkToTC &&
                                <li className="list-inline-item">
                                    <a href={config.linkToTC} target="_blank" rel="noreferrer">{t('footer.nav.tc')}</a>
                                </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterComponent;