import React from 'react';
import { ReactComponent as Icon } from './../../../assets/img/icons/calendarSide.svg';
import { ReactComponent as Check } from './../../../assets/img/icons/check.svg';
import { useTranslation } from 'react-i18next';
import {ProgressBar} from "react-bootstrap";
import PropTypes from "prop-types";


function SideComponent(props) {
    const { t } = useTranslation('text');
    const {onCategoryActivation,onDateActivation,onFormActivation} = props;
    let selectionProgress = 0;
    let progressBar = 0;
    let formText = t('side.form.text');

    // set progress based on category
    if(props.category) {
        selectionProgress = 1;
        progressBar = 30;
    }

    // set progress based on date
    if(props.date) {
        selectionProgress = 2;
        progressBar = 60;
    }

    // set progress based on form
    if(props.formStatus === 'success') {
        selectionProgress = 3;
        progressBar = 100;
        // change form text after form has been successfully send
        formText = t('side.form.textSuccess');
    }

    return (
        <div className="sticky">
            <div className="App-Side">
                <div className="align-middle d-none d-xl-block">
                    <div className="icon-wrapper mb-3">
                        <Icon className="icon" />
                    </div>
                    <p className="small text-center mb-1">{t('side.title')}</p>
                    <p className="status large text-center fw-bold mb-3">{selectionProgress}/3 {t('side.status')}</p>
                    <div className="progress-bar-wrapper mb-4">
                        <ProgressBar now={progressBar} />
                    </div>
                </div>
                <div className="selection-status-list">
                    <div className={`selection-status-list-item ${props.categoryStatus}`}>
                        <div className="selection-status-list-item-inner">
                            <div
                                className="selection-status-list-item-number"
                                onClick={onCategoryActivation}
                            >
                                <div className="selection-status-list-item-number-inner">
                                    <Step
                                        number="1"
                                        status={props.categoryStatus}
                                        color={props.color}
                                    />
                                </div>
                            </div>
                            <div className="selection-status-list-item-content d-none d-xl-block">
                                <div>{t('side.category.title')}</div>
                                <div className="small">{t('side.category.text')}: <b>{props.category}</b></div>
                            </div>
                        </div>
                    </div>
                    <div className={`selection-status-list-item ${props.dateStatus}`}>
                        <div className="selection-status-list-item-inner">
                            <div
                                className="selection-status-list-item-number"
                                onClick={onDateActivation}
                            >
                                <div className="selection-status-list-item-number-inner">
                                    <Step
                                        number="2"
                                        status={props.dateStatus}
                                        color={props.color}
                                    />
                                </div>
                            </div>
                            <div className="selection-status-list-item-content d-none d-xl-block">
                                <div>{t('side.dateTime.title')}</div>
                                <div className="small">{t('side.dateTime.text')}: <b>{props.date} {props.date ? 'Uhr' : ''}</b></div>
                            </div>
                        </div>
                    </div>
                    <div className={`selection-status-list-item ${props.formStatus}`}>
                        <div className="selection-status-list-item-inner">
                            <div
                                className="selection-status-list-item-number"
                                onClick={onFormActivation}
                            >
                                <div className="selection-status-list-item-number-inner">
                                    <Step
                                        number="3"
                                        status={props.formStatus}
                                        color={props.color}
                                    />
                                </div>
                            </div>
                            <div className="selection-status-list-item-content d-none d-xl-block">
                                <div>{t('side.form.title')}</div>
                                <div className="small">{formText}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

// create step view on condition with card status
function Step(props) {
    if (props.status === 'selected' || props.status === 'success') {
        return <Check fill={props.color} />
    }
    return props.number;
}
Step.propTypes = {number: PropTypes.string};

export default SideComponent;