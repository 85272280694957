// send server request
export async function sendData(dataContent, functionName) {
    return new Promise(function (resolve) {
        let postData = [
            {
                "content": dataContent,
                "function": functionName
            }
        ]
        fetch('/sendData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
        })
            .then(response => response.json())
            .then(data => {
                resolve(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    });
}

export default sendData;