import React from "react";
import {withTranslation} from "react-i18next";
import sendData from "../api/Data";

class CancelComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateDay: null,
            dateTime: null,
            cancelStatus: null,
            isLoaded: false,

        }

        this.cancelDate = this.cancelDate.bind(this);
        this.bookNewDate = this.bookNewDate.bind(this);
    }

    componentDidMount() {
        sendData({"guid": this.props.dateGuid}, 'getDate').then(function (data) {
            this.setState({
                cancelStatus: data.status,
                dateDay: data.day,
                dateTime: data.time,
                isLoaded: true
            })
        }.bind(this));
    }

    cancelDate() {
        sendData({"guid": this.props.dateGuid}, 'deleteDate').then(function (data) {
            this.setState({
                cancelStatus: data.status,
            })
        }.bind(this));
    }

    bookNewDate(event) {
        this.props.afterCancellation();
    }

    // Todo: translate
    render() {
        let {dateDay, dateTime, cancelStatus, isLoaded} = this.state;
        if (isLoaded) {
            if (cancelStatus === 'cancel') {
                return (
                    <div>
                        <p>Bitte bestätigen Sie die Stornierung Ihres Termins am {dateDay} um {dateTime} Uhr.</p>
                        <button className="btn btn-primary" onClick={this.cancelDate}>Termin stornieren</button>
                    </div>
                )
            }
            else if (cancelStatus === 'canceled') {
                return (
                    <div>
                        <p>Ihr Termin wurde erfolgreich storniert.</p>
                        <button className="btn btn-primary" onClick={this.bookNewDate}>Neuen Termin buchen</button>
                    </div>
                )
            }
            else {
                return (
                    <div>
                       <p>Ihr Termin wurde bereits storniert.</p>
                        <button className="btn btn-primary" onClick={this.bookNewDate}>Neuen Termin buchen</button>
                    </div>
                )
            }
        }
    };
}

export default withTranslation('text')(CancelComponent);